import { Button } from '@modules/ui/core';
import { ErrorPage } from '@lib/errors';

const PageNotFound = () => {
    //Removing button to avoid clickJacking vulnearbility
    return (
        <ErrorPage title='404' message='PAGE NOT FOUND' fullHeight={true}>
            Please go back to previous page or navigate to the home page.
        </ErrorPage>
    );
};

export { PageNotFound };
